<script setup lang="ts">
import VText from "@magnit/core/src/components/VText/VText.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import PageIcon from "~/assets/svg/500.svg";
import { reloadPage } from "~/utils/etc";

const { send } = useAnalytics();

const onClickReload = () => {
  send("Error:Refresh:Click");
  reloadPage();
};
</script>

<template>
  <div class="error-page-500">
    <div class="error-page-500__content">
      <PageIcon />
      <VText font="title-large" class="error-page-500__text">
        Сайт временно не работает
      </VText>
      <VText class="error-page-500__text">
        Мы уже чиним эту проблему — пожалуйста, попробуйте снова через 15 минут
      </VText>
      <VButton
        class="error-page-500__button"
        full-width
        theme="secondary"
        @click="onClickReload"
      >
        Обновить
      </VButton>
    </div>
  </div>
</template>

<style lang="postcss">
.error-page-500 {
  height: 100vh;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 385px;
    padding: 0 var(--pl-unit-x5) var(--pl-unit-x4);
    text-align: center;
  }

  &__button {
    margin-top: var(--pl-unit-x6);
  }

  &__text {
    display: inline-block;
    margin-bottom: var(--pl-unit-x2);
  }
}
</style>
