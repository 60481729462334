import { defineStore } from "pinia";
import { reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useLocalStorage } from "@vueuse/core";
import { useAnalyticManager } from "@magnit/analytic-events";
import { fetchUserBalance, fetchUserBenefits, fetchUserNextStickerExpiration } from "@magnit/layer-api/api";
import { TickStatus, TickType } from "@magnit/layer-api/typings";
import { FetchError } from "ofetch";
import { ErrorCodes, HttpCodes, urls } from "~/api/config";
import useNotification from "~/composables/useNotification";
import useResponseCheck from "~/composables/useResponseCheck";
import { routeParams } from "~/utils/routes";

interface IUserProfile {
  userId?: string;
  birthDate?: Date;
  email?: string;
  firstName?: string;
  isEmailConfirmed: boolean;
  phone: string;
}

interface IUserCard {
  id: string;
  identifierNo: string;
  isIdentifierTypeVirtual: boolean;
  status: string;
}

interface IUserPermissions {
  isEmailPermitted: boolean;
  isSMSPermitted: boolean;
  isDigitalReceiptPermitted: boolean;
}

interface IUserProgress {
  bonusPercentage?: number;
  currentBonusPercentage?: number;
  currentMoneyAmount?: number;
  currentProducts?: number;
  leftToBuyMoneyAmount?: number;
  leftToBuyProducts?: number;
  maxBonus: boolean;
  moneyAmount?: number;
  products?: number;
  status?: TickStatus;
  type?: TickType;
}

interface IUserSticker {
  totalStickerBalance: number;
  type: string;
}

interface IUserBalance {
  bonuses: {
    totalPointBalance: number;
    totalExpressPoints: number;
  };
  stickers: {
    expirationDate?: string;
    expirationStickerBalance?: number;
  } & IUserSticker;
  progress: IUserProgress;
}

type IStoreStatus = "initial" | "pending" | "success" | "error";

interface IUserStatus {
  balance: IStoreStatus;
  balanceError: string | null;
  cardMerge: IStoreStatus;
  cardMergeError: string | null;
  cardsList: IStoreStatus;
  delete: IStoreStatus;
  emailConfirmation: IStoreStatus;
  profile: IStoreStatus;
  profileUpdate: IStoreStatus;
  register: IStoreStatus;
  permissions: IStoreStatus;
  permissionsUpdate: IStoreStatus;
}

interface IMagnitIdValidationError {
  field: string;
  errCode: string;
}
export interface IMagnitIdRegistrationError {
  code: string;
  debugMessage: string;
  message: string;
  validationErrors: IMagnitIdValidationError[];
}

type IProfileKeys = keyof IUserProfile;
type IPermissionsKeys = keyof IUserPermissions;

export const useUserStore = defineStore("user", () => {
  const { warning: showWarningToast } = useNotification();
  const { send } = useAnalytics();
  const { hasError } = useResponseCheck();
  const route = useRoute();
  const { globalParams } = useAnalyticManager();
  const { getMagnitCodeCookie } = useAuth();

  const savedUserId = useLocalStorage<{
    uuid: string;
  }>(APP_LOCAL_STORAGE_KEYS.uuid, {
    uuid: "",
  });

  const profile = ref<IUserProfile>({
    userId: undefined,
    birthDate: undefined,
    email: "",
    isEmailConfirmed: false,
    firstName: "",
    phone: "",
  });

  const cards = ref<IUserCard[]>([]);

  const permissions = ref<IUserPermissions>({
    isEmailPermitted: false,
    isSMSPermitted: true,
    isDigitalReceiptPermitted: false,
  });

  const balance = ref<IUserBalance>();

  const status = reactive<IUserStatus>({
    balance: "initial",
    balanceError: null,
    cardsList: "initial",
    cardMerge: "initial",
    cardMergeError: null,
    delete: "initial",
    emailConfirmation: "initial",
    profile: "initial",
    profileUpdate: "initial",
    register: "initial",
    permissions: "initial",
    permissionsUpdate: "initial",
  });

  watch(
    profile,
    (next) => {
      globalParams({
        magnit_id: next.userId || null,
      });
    },
    { immediate: true },
  );

  watch(
    savedUserId,
    (next) => {
      if (next.uuid) {
        profile.value.userId = savedUserId.value.uuid;
      }
    },
    { immediate: true },
  );

  function setSavedUUID(uuid: string) {
    savedUserId.value.uuid = uuid;
  }

  function clearSavedUUID() {
    savedUserId.value.uuid = "";
  }

  async function register(
    patch: Pick<IUserProfile, "firstName" | "birthDate" | "email"> &
      Pick<IUserPermissions, "isSMSPermitted">,
  ) {
    status.register = "pending";

    profile.value = {
      ...profile.value,
      firstName: patch.firstName,
      birthDate: patch.birthDate,
      email: patch.email,
    };

    permissions.value.isSMSPermitted = patch.isSMSPermitted;

    const requestData = {
      magnitIDCode: getMagnitCodeCookie(),
      birthDate: profile.value.birthDate
        ? getFormattedYYYYMMDD(profile.value.birthDate)
        : undefined,
      firstName: profile.value.firstName,
      email: profile.value.email || undefined,
    };

    if (!profile.value.birthDate) {
      delete requestData.birthDate;
    }

    if (!profile.value.email) {
      delete requestData.email;
    }

    const { data, error } = await useTransport<{
      userId: string;
      birthDate?: string; // "1990-01-01"
      lastName?: string;
      email?: string;
      firstName?: string;
      gender?: string;
      phone: string;
      isEmailConfirmed: boolean;
    }>(urls.user.register, {
      gateway: "magnit-id",
      method: "POST",
      body: requestData,
    });

    if (data.value) {
      profile.value.userId = data.value.userId;

      send("RegistrationPage:Success");

      status.register = "success";

      if (permissions.value.isSMSPermitted) {
        await updatePermissions(
          permissions.value.isSMSPermitted,
          "isSMSPermitted",
        );
      }
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      status.register = "initial";
      throw error.value.data;
    }
  }

  async function getProfile() {
    status.profile = "pending";

    const { data, error } = await useTransport<{
      userId: string;
      birthDate?: string; // "1990-01-01"
      lastName?: string;
      email?: string;
      firstName?: string;
      gender?: string;
      phone: string;
      isEmailConfirmed: boolean;
    }>(urls.user.profile, {
      gateway: "magnit-id",
      method: "GET",
      permissions: {
        jwt: true,
      },
    });

    if (data.value) {
      const { birthDate, ...etc } = data.value;

      setSavedUUID(data.value.userId);

      profile.value = {
        ...profile.value,
        ...etc,
        birthDate: birthDate ? new Date(birthDate) : undefined,
      };

      status.profile = "success";
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      status.profile = "error";
    }
  }

  async function getPermissions() {
    status.permissions = "pending";

    const { data, error } = await useTransport<{
      isDigitalReceiptPermitted: boolean;
      isEmailPermitted: boolean;
      isSMSPermitted: boolean;
    }>(urls.user.permissions, {
      method: "GET",
      permissions: {
        jwt: true,
      },
    });

    if (data.value) {
      permissions.value = data.value;
      status.permissions = "success";
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      status.permissions = "error";

      await hasError(error.value);
    }
  }

  async function updatePermissions(
    value: IUserPermissions[IPermissionsKeys],
    keyName: IPermissionsKeys,
  ) {
    status.permissionsUpdate = "pending";

    const patch: Record<string, unknown> = {};
    patch[keyName] = value;

    const { data, error } = await useTransport<{
      isDigitalReceiptPermitted: boolean;
      isEmailPermitted: boolean;
      isSMSPermitted: boolean;
    }>(urls.user.permissions, {
      method: "PATCH",
      body: patch,
      permissions: {
        jwt: true,
      },
    });

    if (data.value) {
      permissions.value = data.value;
      status.permissionsUpdate = "success";
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      status.permissionsUpdate = "error";

      await hasError(error.value);
    }
  }

  async function updateProfile(
    patch: Partial<Record<IProfileKeys, string>>,
  ) {
    let customError = "";
    status.profileUpdate = "pending";

    const { data, error } = await useTransport<{
      userId: string;
      birthDate?: string; // "1990-01-01"
      lastName?: string;
      email?: string;
      firstName?: string;
      gender?: string;
      phone: string;
      isEmailConfirmed: boolean;
    }>(urls.user.profile, {
      gateway: "magnit-id",
      method: "PATCH",
      body: patch,
      permissions: {
        jwt: true,
      },
    });

    if (data.value) {
      const { birthDate, ...etc } = data.value;

      profile.value = {
        ...profile.value,
        ...etc,
        birthDate: birthDate ? new Date(birthDate) : undefined,
      };

      status.profileUpdate = "success";
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      if (error.value?.data?.code === ErrorCodes.EmailForbidden) {
        customError = "Укажите личную почту";
      }
      status.profileUpdate = "error";

      await hasError(error.value);

      return customError;
    }
  }

  async function confirmEmail(email: string) {
    status.emailConfirmation = "pending";

    const { data, error } = await useTransport(urls.user.emailConfirmation, {
      method: "POST",
      gateway: "magnit-id",
      body: { email },
      permissions: {
        jwt: true,
      },
    });

    if (data.value !== null) {
      status.emailConfirmation = "success";
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      status.emailConfirmation = "error";

      if (await hasError(error.value)) {
        return;
      }

      showWarningToast({
        primaryButtonText: "Обновить",
        title: "Мы не смогли отправить подтверждение",
        text: "Проверьте подключение и обновите страницу, а затем повторите",
        onPrimaryClick() {
          location.reload();
        },
      });
    }
  }

  async function getBalance() {
    try {
      status.balance = "pending";
      status.balanceError = null;

      const [balanceResponse, benefitsResponse, nextSticerExpirationResponse] = await Promise.all([fetchUserBalance(), fetchUserBenefits(), fetchUserNextStickerExpiration()]);
      const benefits = benefitsResponse.benefits;
      const STICKER_TYPE = "StickerRK2";
      const stickerBalance = balanceResponse?.items?.find((item) => item.type === STICKER_TYPE);

      balance.value = {
        bonuses: balanceResponse,
        progress: {
          maxBonus:
              benefits.ticks[benefits.ticks.length - 1].status === TickStatus.done,
          currentBonusPercentage: benefits.currentBonusPercentage,
          ...benefits.ticks.find(({ status }) => status === TickStatus.inProgress),
        },
        stickers: {
          expirationDate: nextSticerExpirationResponse.expirationDate,
          expirationStickerBalance: nextSticerExpirationResponse.points,
          totalStickerBalance: stickerBalance?.points || 0,
          type: STICKER_TYPE,
        },
      };

      status.balance = "success";
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        status.balance = "error";
        status.balanceError = error?.data.code || null;

        send("MyBonuses:ErrorGet:View", {
          type_error: error?.data.code,
        });

        await hasError(error);
      }
    }
  }

  async function getCards() {
    status.cardsList = "pending";

    const { data, error } = await useTransport<{
      identifiers: {
        cobranded: boolean;
        id: string;
        identifierNo: string;
        identifierTypeCode: string;
        isIdentifierTypeVirtual: boolean;
        redemptionEnabled: boolean;
        status: string;
        statusName: string;
      }[];
      isMergeEnabled: boolean;
      maxPlasticCards: number;
    }>(urls.user.cards, {
      method: "GET",
      permissions: {
        jwt: true,
      },
    });

    if (data.value) {
      cards.value = data.value.identifiers;
      status.cardsList = "success";
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      status.cardsList = "error";

      await hasError(error.value);
    }
  }

  async function mergeCard(patch: {
    cvc: string;
    identifier: string;
    token: string;
  }) {
    status.cardMerge = "pending";
    status.cardMergeError = null;

    const { data, error } = await useTransport(urls.user.cardMerge, {
      method: "POST",
      body: {
        additionalIdentifierCvv: patch.cvc,
        additionalIdentifierNo: patch.identifier,
        card_type: "loyalty",
      },
      permissions: {
        jwt: true,
      },
      headers: {
        "X-Captcha-Token": patch.token,
      },
    });

    if (data.value !== null) {
      status.cardMerge = "success";
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      status.cardMerge = "error";
      status.cardMergeError = error.value.data.code || null;

      send("AddCardPage:Error:View", {
        error_type: error.value?.data.code,
        chapter: routeParams.firstAddCard in route.query ? "auth" : "sett",
      });

      await hasError(error.value);
    }
  }

  const getProfileSummary = async () => {
    await getProfile();
    await getPermissions();
  };

  return {
    confirmEmail,
    getBalance,
    getCards,
    getProfile,
    getProfileSummary,
    updateProfile,
    setSavedUUID,
    clearSavedUUID,
    getPermissions,
    updatePermissions,
    mergeCard,
    register,
    profile,
    cards,
    permissions,
    balance,
    status,
  };
});
